import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as styles from './breadcrumb.module.scss'
import useLinkLocalized from '../../../hooks/useLinkLocalized'
import { useTranslation } from '../../../hooks/useTranslation'

interface BreadcrumbProps {
  data: any[]
  color?: string
}

export default function Breadcrumb(props: BreadcrumbProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )
  const { t } = useTranslation('common')
  const { data, color } = props

  return (
    <div className={styles.breadcrumb}>
      <div className="container">
        <ol
          itemScope
          itemType="https://schema.org/BreadcrumbList"
          className="flex items-center"
        >
          <li
            key={`bc-init`}
            className={`${styles.breadcrumbItem} hover:text-vert-fonce`}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            <a
              itemProp="item"
              href={`${site.siteMetadata.siteUrl}${useLinkLocalized({
                url: '/',
              })}`}
              className="body--3"
            >
              <span itemProp="name">{t('sitemap.Accueil', 'common')}</span>
            </a>
            <meta itemProp="position" content={'0'} />
          </li>
          {data
            .filter((item: any) => item.title && item.title !== '')
            .map((item: any, index: number) => (
              <li
                key={`bc-${index}`}
                className={`${styles.breadcrumbItem} hover:text-vert-fonce`}
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <span className={`${styles.breadcrumbItemIcon}`}>〉</span>
                {index < data.length - 1 ? (
                  <a
                    itemProp="item"
                    href={`${site.siteMetadata.siteUrl}${item.link}`}
                    className="body--3"
                  >
                    <span itemProp="name">{item.title}</span>
                  </a>
                ) : (
                  <strong itemProp="item" className="body--3">
                    <span itemProp="name">{item.title}</span>
                  </strong>
                )}
                <meta itemProp="position" content={(index + 1).toString()} />
              </li>
            ))}
        </ol>
      </div>
    </div>
  )
}
